import React from 'react';

function ReservationScheduleBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Rezervacije</h4>

                <small className="text-center mb--30">Ogled si lahko rezervirate v času uradnih ur TIC Litija.</small>

                <p><strong>Ponedeljek</strong> </p>
                <p>Zaprto</p>

                <p className="mt--15"><strong>Torek</strong> </p>
                <p>10-16h</p>

                <p className="mt--15"><strong>Sreda</strong> </p>
                <p>9-17h</p>

                <p className="mt--15"><strong>Četrtek</strong> </p>
                <p>Zaprto</p>

                <p className="mt--15"><strong>Petek</strong> </p>
                <p>9-15h</p>

                <p className="mt--15"><strong>Sobota</strong> </p>
                <p className="text-center">
                    <span>9-12h</span>
                    <div className="saturday-visit-text saturday-special-offer">
                        Prisotni na lokaciji recepcije rudnika.
                    </div>

                </p>


                <p className="mt--15"><strong>Nedelja in prazniki</strong> </p>
                <p>Zaprto</p>

            </div>
        </div>
    );
}

export default ReservationScheduleBox;