import React, {useState} from 'react';
import {
    combinationOfRowsPricing,
    mainRowPrices,
    transportRowPrices,
} from "./pricing-data";
import PriceItem from './PriceItem';
import TicInfoBox from "../info/TicInfo";
import OpenHoursBox from "../info/OpenHoursBox";
import ReservationScheduleBox from "./ReservationScheduleBox";

function ServicesPricing(props) {
    return (
        <div>
            <div className="about-wrapper pb--120 pt--120 bg_color--3">
                <div className="container">
                    <div className="row row--20">

                        <div className="col-12 col-lg-8">
                            <div className="about-inner inner">
                                <div className="section-title">
                                    <h3 className="title">Cenik ogledov</h3>
                                    <p className="description mb-0 mt-0" style={{fontSize: "0.925rem"}}>Za obisk rudnika je <strong>obvezna rezervacija</strong> na telefonsko številko <strong>051 312 739 ali 070 700 484</strong> ter <strong>info@visitlitija.si</strong>. Cenik velja od 1. 2. 2024 dalje. <strong>Plačilo na blagajni je možno le z gotovino.</strong></p>

                                    <p className="description mt-2 mb-2" style={{fontSize: "0.925rem"}}>
                                        Možnost ogleda Izvoznega rova brez predhodne najave
                                        vsako soboto ob 10:00.
                                    </p>
                                </div>

                                <h4 className="mt--30 mb--0">IZVOZNI ROV</h4>
                                <div className="section-title mt--0 mb--30">
                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Za otroke do 3. leta (razen vodenje s škratom), turistične vodnike, novinarje ter spremljevalce skupin in invalidov, je ogled brezplačen.
                                    </p>
                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Oglede organiziramo za najmanj 4 osebe in istočasno za največ 25 oseb. Za večje skupine ogled
                                        organiziramo v 15 minutnih zamikih.
                                    </p>
                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Sitarjevško malico in Škratov šmorn organiziramo za skupine najmanj 10 obiskovalcev.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Storitev</th>
                                            <th scope="col">Posamezniki</th>
                                            <th scope="col ">Skupina od 10 do 25 oseb</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transportRowPrices.map((priceItem, index) => (
                                            <PriceItem priceItem={priceItem} numOfColumns={3} key={'transport-' + index} />
                                        ))}
                                    </tbody>
                                </table>

                                <h4 className="mt--60 mb--0">GLAVNI ROV</h4>

                                <div className="section-title mt--0 mb--30">
                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Za turistične vodnike, novinarje ter spremljevalce skupin, je ogled brezplačen. Za otroke do 10 let in
                                        gibalno ovirane ogled ni možen.
                                    </p>

                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Oglede organiziramo za najmanj 4 osebe in istočasno za največ 10 oseb. Za večje skupine ogled
                                        organiziramo v 15 minutnih zamikih.
                                    </p>

                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Sitarjevško malico in Škratov šmorn organiziramo za skupine najmanj 10 obiskovalcev.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="60%" scope="col">Storitev</th>
                                            <th scope="col">Skupina od 4 do 8 oseb</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mainRowPrices.map((priceItem, index) => (
                                            <PriceItem numOfColumns={2} priceItem={priceItem} key={'main-' + index}/>
                                        ))}
                                    </tbody>
                                </table>

                                {/*new service*/}
                                <div className="section-title mt--120 mb--30">
                                    <h3 className="title">Kombiniran ogled Izvoznega in Glavnega rova</h3>
                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Za turistične vodnike, novinarje ter spremljevalce skupin, je ogled brezplačen. Za otroke do 10 let in
                                        gibalno ovirane ogled ni možen.
                                    </p>

                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Oglede organiziramo za najmanj 4 osebe in istočasno za največ 10 oseb. Za večje skupine ogled
                                        organiziramo v 15 minutnih zamikih.
                                    </p>

                                    <p className="description mt-0 mb-2" style={{fontSize: "0.925rem"}}>
                                        Sitarjevško malico in Škratov šmorn organiziramo za skupine najmanj 10 obiskovalcev.
                                    </p>
                                </div>

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="60%" scope="col">Storitev</th>
                                            <th scope="col">Skupina od 4 do 8 oseb</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { combinationOfRowsPricing.map((priceItem, index) => (
                                            <PriceItem priceItem={priceItem} numOfColumns={2} key={'combination-' + index} />
                                        )) }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="col-12 col-lg-4">
                            <ReservationScheduleBox/>
                            <OpenHoursBox/>
                            {/*<TicInfoBox/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesPricing;
