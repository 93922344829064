export const transportRowPrices = [
    {
        title: 'Otroci 4 - 15 let',
        price: '7 € na osebo',
        group: '6 € na osebo'
    },
    {
        title: 'Dijaki, študenti, upokojenci, invalidi',
        price: '8 € na osebo',
        group: '6 € na osebo'
    },
    {
        title: 'Odrasli',
        price: '10 € na osebo',
        group: '8 € na osebo'
    },
    {
        title: 'Vodenje s škratom (od 0 - 99 let)',
        price: '10 € na osebo',
        group: '8 € na osebo'
    },
    {
        title: 'Sitarjevška malica (doplačilo)',
        price: '11 € na osebo',
        group: ''
    },
    {
        title: 'Škratov šmorn (doplačilo)',
        price: '10 € na osebo',
        group: ''
    },
];

export const mainRowPrices = [
    {
        title: 'Otroci 10 - 15 let',
        price: '15 € na osebo',
    },
    {
        title: 'Dijaki, študenti, upokojenci, invalidi',
        price: '21 € na osebo',
    },
    {
        title: 'Odrasli',
        price: '23 € na osebo',
    },
    {
        title: "Sitarjevška malica (doplačilo)",
        price: '11 € na osebo',
    },
    {
        title: "Škratov šmorn (doplačilo)",
        price: '10 € na osebo',
    }
];

export const combinationOfRowsPricing = [
    {
        title: 'Otroci 10 - 15 let',
        price: '22 € na osebo',
    },
    {
        title: 'Dijaki, študenti, upokojenci, invalidi',
        price: '24 € na osebo',
    },
    {
        title: 'Odrasli',
        price: '29 € na osebo',
    },
    {
        title: 'Sitarjevška malica (doplačilo)',
        price: '11 € na osebo',
    },
    {
        title: 'Škratov šmorn (doplačilo)',
        price: '10 € na osebo',
    },
];