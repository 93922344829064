import React from 'react';

function OpenHoursBox(props) {
    return (
        <div className="rn-address bg_color--3">
            <div className="inner d-flex justify-content-center align-items-center flex-column">

                <h4 className="title text-center d-inline-block mt--15">Urnik ogledov Rudnika Sitarjevec Litija</h4>


                <p className="mt--15"><strong>Četrtek </strong> </p>
                <p>9-12h in 15-18h</p>

                <p className="mt--15"><strong>Petek </strong> </p>
                <p>9-12h in 15-18h</p>

                <p className="mt--15"><strong>Sobota</strong> </p>
                <p>9-12h in 15-18h</p>

                <p className="mt--15"><strong>Nedelja </strong> </p>
                <p>9-12h in 15-18h</p>

                <small className="text-center mt--30 saturday-special-offer"><strong>Vsako soboto ob 10:00 možnost ogleda Izvoznega rova rudnika brez predhodne najave.</strong></small>

                <small className="text-center mt--30">Potrebno predhodno naročilo. Skupine možno naročiti izven navedenih ur.</small>
            </div>
        </div>
    );
}

export default OpenHoursBox;